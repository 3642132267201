import logo from './logo.svg';
import './App.scss';
import ProductViewCheckout from "./components/ProductViewCheckout";

function App() {
  return (
    <>
      <ProductViewCheckout />
    </>
  );
}

export default App;
