import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useReducer,
} from "react";
import { useLocalStorage } from "react-use";
// import { db, auth, storage, db2, auth2, storage2 } from "../database/Firebase";
// import { collection, doc, setDoc, getDoc, getDocs } from "firebase/firestore";

import { db2 } from "../database/Firebase";
import {
  collection,
  getDocs,
  getDoc,
  query as fsquery,
  where,
} from "firebase/firestore";

const LOCAL_STORAGE_KEY = "DJ_OBAH_SHOP";

const INITIAL_STATE = [
  // initial state
];

const reducer = (state, action) => {
  let update = [];

  switch (action.type) {
    case "ADD":
      let i = state.findIndex((item) => item.id === action.id);
      //console.log('action', action)
      if (i === -1) {
        state.push(action);
        update = state;
      } else {
        // check the details of item before updating, it might still be unique
        if (action.size === state[i].size && action.color === state[i].color) {
          let qty = 0;
          qty = parseInt(state[i].quantity);
          qty = qty + parseInt(action.quantity);
          state[i] = action;
          state[i].quantity = qty;
        } else {
          state.push(action);
        }
        update = state;
      }

      break;
    case "REDUCE":
      // code block
      break;
    case "DELETE":
      // code block
      break;
    default:
    // code block
  }

  // return updated state based on `action.type`
  return update;
};

const usePersistReducer = () => {
  // grab saved value from `localStorage` and
  // a function to update it. if
  // no value is retrieved, use `INITIAL_STATE`
  const [savedState, saveState] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    INITIAL_STATE
  );

  // wrap `reducer` with a memoized function that
  // syncs the `newState` to `localStorage` before
  // returning `newState`. memoizing is important!
  const reducerLocalStorage = useCallback(
    (state, action) => {
      //console.log('**************************')
      //console.log('state', state)
      // get a live update here too
      let cartdata = JSON.parse(localStorage.getItem("DJ_OBAH_SHOP"));
      //console.log('cartdata:', cartdata)
      //console.log('**************************')
      const newState = reducer(cartdata, action);

      saveState(newState);

      return newState;
    },
    [saveState]
  );

  // use wrapped reducer and the saved value from
  // `localStorage` as params to `useReducer`.
  // this will return `[state, dispatch]`
  return useReducer(reducerLocalStorage, savedState);
};

const ProductViewCheckout = ({ query, funcProp }) => {
  const [releaseData, setReleaseData] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [checkoutView, setCheckoutView] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentProductDetails, setCurrentProductDetails] = useState({});
  const [productNotReady, setProductNotReady] = useState(true);
  const [state, dispatch] = usePersistReducer();
  const [prodviewquery, setProdviewquery] = useState(query);

  useEffect(() => {
    const fetchData = async () => {
      console.log("productview currentProductDetails", currentProductDetails);
      setCheckoutView(true);
      console.log("checkoutView", checkoutView);
      //console.log('dsfsdcds', cartTrigger)
      const releasePath = query;
      console.log("productview  query", releasePath);
      if (releasePath) {
        const citiesRef = collection(
          db2,
          "users/Bs7gqBrNUtMBmM7jgEyGmCKtSNl1/ecommerce" //ebz
        );

        const q = fsquery(citiesRef, where("releasePath", "==", releasePath));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());

          let url = doc
            .data()
            .customFieldList.find(
              (customField) => customField.customName === "audioURL"
            );
          if (url && url["customValue"]) {
            url = url["customValue"];
          }

          let duration = doc
            .data()
            .customFieldList.find(
              (customField) => customField.customName === "audioDuration"
            );
          if (duration && duration["customValue"]) {
            duration = duration["customValue"];
          }

          // get the artist name
          let artistname = doc
            .data()
            .customFieldList.find(
              (customField) => customField.customName === "artist"
            );
          if (artistname && artistname["customValue"]) {
            artistname = artistname["customValue"];
          }

          // get size
          let sizeList = [];
          let sl = doc
            .data()
            .customFieldList.find(
              (customField) => customField.customName === "size"
            );
          if (sl && sl["customValue"]) {
            sizeList = sl["customValue"].split(",");
          }

          setReleaseData((oldData) => [
            {
              title: doc.data().product_name,
              desc: doc.data().product_desc,
              releasePath: doc.data().releasePath,
              price: parseInt(doc.data().price),
              artist: artistname,
              product_id: doc.data().product_id,
              coverImage: doc.data().imageList[0].url,
              productImageList: doc.data().imageList,
              productSizeList: sizeList,
              url: url,
              duration: duration,
              tags: doc.data().tags.split(","),
              userplaylist: "false",
            },
          ]);

          setCurrentProductDetails({
            ...currentProductDetails,
            product_id: doc.data().product_id,
            title: doc.data().product_name,
            price: parseInt(doc.data().price),
          });

          setCurrentImage(doc.data().imageList[0].url);

          setCheckoutView(true);
        });
      }

      // if (releasePath) {
      //   const citiesRef = collection(
      //     db2,
      //     "users/Bs7gqBrNUtMBmM7jgEyGmCKtSNl1/ecommerce" //ebz
      //   );

      //   const q = fsquery(
      //     citiesRef,
      //     where("releasePath", "==", releasePath)
      //   );

      //   const querySnapshot = await getDocs(q);
      //   //console.log('querySnapshot', querySnapshot)
      //   querySnapshot.forEach((doc) => {
      //     console.log('doc', doc)
      //     let url = doc
      //       .data()
      //       .customFieldList.find(
      //         (customField) => customField.customName === "audioURL"
      //       );
      //     if (url && url["customValue"]) {
      //       url = url["customValue"];
      //     }

      //     let duration = doc
      //       .data()
      //       .customFieldList.find(
      //         (customField) => customField.customName === "audioDuration"
      //       );
      //     if (duration && duration["customValue"]) {
      //       duration = duration["customValue"];
      //     }

      //     // get the artist name
      //     let artistname = doc
      //       .data()
      //       .customFieldList.find(
      //         (customField) => customField.customName === "artist"
      //       );
      //     if (artistname && artistname["customValue"]) {
      //       artistname = artistname["customValue"];
      //     }

      //     // get size
      //     let sizeList = [];
      //     let sl = doc
      //       .data()
      //       .customFieldList.find(
      //         (customField) => customField.customName === "size"
      //       );
      //     if (sl && sl["customValue"]) {
      //       sizeList = sl["customValue"].split(",");
      //     }

      //     setReleaseData((oldData) => [
      //       {
      //         title: doc.data().product_name,
      //         desc: doc.data().product_desc,
      //         releasePath: doc.data().releasePath,
      //         price: parseInt(doc.data().price),
      //         artist: artistname,
      //         product_id: doc.data().product_id,
      //         coverImage: doc.data().imageList[0].url,
      //         productImageList: doc.data().imageList,
      //         productSizeList: sizeList,
      //         url: url,
      //         duration: duration,
      //         tags: doc.data().tags.split(","),
      //         userplaylist: "false",
      //       },
      //     ]);
      //     setCurrentProductDetails({
      //       ...currentProductDetails,
      //       product_id: doc.data().product_id,
      //       title: doc.data().product_name,
      //       price: parseInt(doc.data().price),
      //     });
      //     setCurrentImage(doc.data().imageList[0].url);
      //   });
      //   //
      // }
    };
    fetchData().catch(console.error);
  }, [query]);

  useEffect(() => {
    // setProdviewquery(query)
    console.log("heckoutView:", checkoutView);
  }, [checkoutView]);

  // useEffect(() => {
  //   //console.log('get checkout data from localStorage and rerender yes?!!!', state)
  //   //const fetchData = async () => {

  //   //};
  //   //fetchData().catch(console.error);

  //   console.log('from outside query', query)

  // }, [query]);

  const handleCurrentImage = (item) => {
    setCurrentImage(item.url);
    setCurrentProductDetails({
      ...currentProductDetails,
      color: item.desc,
      image: item.url,
    });
  };

  const handleCurrentSize = (size) => {
    setCurrentProductDetails({ ...currentProductDetails, size: size });
  };

  const handleAddToCart = (item) => {
    // if not GB make the price at least £30
    // GB £20

    //console.log('handleAddToCart:', item)
    //console.log('currentProductDetails:', currentProductDetails)

    setCurrentProductDetails({
      ...currentProductDetails,
      product_id: item.product_id,
      quantity: quantity,
    });
    const product = {
      quantity: quantity,
      id: item.product_id,
      title: item.title,
      desc: item.desc,
      size: currentProductDetails.size,
      color: currentProductDetails.color,
      image: currentProductDetails.image,
      price: currentProductDetails.price,
      type: "ADD",
    };
    //addProductToCart(product);
    //console.log('addProductToCart - localStorage', product)
    dispatch(product);
  };

  const handleCheckout = () => {
    //console.log('change to checkout component view')
    setCheckoutView(true);
  };

  const Checkout = () => <div>Loading...{query}</div>;

  const ProductView = () => (
    <section className="pb-20 shop-container">
      <div className="container mx-auto -mt-24 w-full md:w-9/12 px-4 bg-transparent relative">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <div className="bg-white mb-8 shadow-lg text-center ">
            <div className="releaseSquare  p-[16px] lg:p-[32px]">
              {releaseData.map((item, index) => (
                <div className="releaseSquare--info" key={index}>
                  <img src={currentImage} className="releaseImg" />
                  <div className="font-black text-xl">{item.title}</div>
                  <div className="font-black">
                    {currentProductDetails.color}
                  </div>
                  <div className="font-black">{currentProductDetails.size}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white mb-8 shadow-lg text-black">
            <div className="releaseSquare  lg:p-[32px]">
              {releaseData.map((item, index) => (
                <div className="releaseSquare--info w-full" key={index}>
                  <div className="relative mx-auto max-w-screen-xl px-4 py-8">
                    <div className="grid grid-cols-1 items-start gap-8 md:grid-cols-1">
                      <div className="sticky top-0">
                        <div className="mt-8 flex justify-between">
                          <div className="max-w-[35ch] space-y-2">
                            <h1 className="text-4xl font-bold">{item.title}</h1>
                            <div className="text-3xl font-bold">
                              USD ${item.price}
                            </div>

                            <p className="text-sm">Highest Rated Product</p>

                            <div className="-ms-0.5 flex">
                              <svg
                                className="h-5 w-5 text-yellow-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>

                              <svg
                                className="h-5 w-5 text-yellow-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>

                              <svg
                                className="h-5 w-5 text-yellow-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>

                              <svg
                                className="h-5 w-5 text-yellow-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>

                              <svg
                                className="h-5 w-5 text-gray-200"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                            </div>
                          </div>

                          {/* <p className="text-4xl font-bold"> */}
                          {/* USD ${item.price} */}
                          {/* <CurrencyFormat
                                  value={item.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"£"}
                                /> */}
                          {/* </p> */}
                        </div>

                        <div className="mt-4">
                          <div className="prose max-w-none text-black">
                            <p className="sm:text-3xl">{item.desc}</p>
                          </div>

                          <button className="mt-2 text-sm font-medium underline">
                            Read More
                          </button>
                        </div>

                        <div className="mt-8">
                          <fieldset>
                            <legend className="mb-1 text-lg font-medium">
                              Color
                            </legend>

                            <div className="flex flex-wrap gap-1">
                              {item.productImageList.map((coloritem, index) => (
                                <label
                                  htmlFor={"color_" + coloritem.desc}
                                  className="cursor-pointer"
                                  key={index}
                                  onClick={() => handleCurrentImage(coloritem)}
                                >
                                  <input
                                    type="radio"
                                    name="color"
                                    id={"color_" + coloritem.desc}
                                    className="peer sr-only"
                                  />

                                  <span className="group inline-block rounded-full border px-3 py-1 text-lg font-medium peer-checked:bg-black peer-checked:text-white pill">
                                    {coloritem.desc}
                                  </span>
                                </label>
                              ))}
                            </div>
                          </fieldset>

                          <fieldset className="mt-4">
                            <legend className="mb-1 text-lg font-medium">
                              Size
                            </legend>

                            <div className="flex flex-wrap gap-1">
                              {item.productSizeList.map((size, index) => (
                                <label
                                  htmlFor={"size_" + size}
                                  className="cursor-pointer"
                                  key={index}
                                  onClick={() => handleCurrentSize(size)}
                                >
                                  <input
                                    type="radio"
                                    name="size"
                                    id={"size_" + size}
                                    className="peer sr-only"
                                  />

                                  <span className="group inline-flex h-8 w-8 items-center justify-center rounded-full border text-lg font-medium peer-checked:bg-black peer-checked:text-white pill">
                                    {size}
                                  </span>
                                </label>
                              ))}
                            </div>
                          </fieldset>

                          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                            <div className="custom-number-input h-10 w-full col-span-1 lg:col-span-2">
                              <label
                                htmlFor="custom-input-number"
                                className="w-full text-gray-700 text-lg font-semibold"
                              >
                                Quantity
                              </label>
                              <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                                <input
                                  type="number"
                                  className="outline-none focus:outline-none text-center w-20 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                                  name="custom-input-number"
                                  id="quantity"
                                  min="1"
                                  value={quantity}
                                  onChange={(e) => setQuantity(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="custom-number-input mt-10 h-10 w-full col-span-1 lg:col-span-2">
                              <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1 items-center justify-end gap-x-8">
                                <button
                                  onClick={() => handleAddToCart(item)}
                                  className="block rounded bg-green-600 px-2 py-3 text-2xl font-medium text-white hover:bg-green-500 w-full"
                                >
                                  Add to Cart
                                </button>

                                <button
                                  onClick={() => funcProp("world")}
                                  className="block rounded bg-green-600 px-2 py-3 text-2xl font-medium text-white hover:bg-green-500 w-full"
                                >
                                  Checkout
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <>
      {checkoutView ? (
        <section className="pb-20 shop-container">
          <div className="container mx-auto -mt-24 w-full md:w-9/12 px-4 bg-transparent relative">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
              <div className="bg-white mb-8 shadow-lg text-center ">
                <div className="releaseSquare  p-[16px] lg:p-[32px]">
                  {releaseData.map((item, index) => (
                    <div className="releaseSquare--info" key={index}>
                      <img src={currentImage} className="releaseImg" />
                      <div className="font-black text-xl">{item.title}</div>
                      <div className="font-black">
                        {currentProductDetails.color}
                      </div>
                      <div className="font-black">
                        {currentProductDetails.size}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-white mb-8 shadow-lg text-black">
                <div className="releaseSquare  lg:p-[32px]">
                  {releaseData.map((item, index) => (
                    <div className="releaseSquare--info w-full" key={index}>
                      <div className="relative mx-auto max-w-screen-xl px-4 py-8">
                        <div className="grid grid-cols-1 items-start gap-8 md:grid-cols-1">
                          <div className="sticky top-0">
                            <div className="mt-8 flex justify-between">
                              <div className="max-w-[35ch] space-y-2">
                                <h1 className="text-4xl font-bold">
                                  {item.title}
                                </h1>
                                <div className="text-3xl font-bold">
                                  USD ${item.price}
                                </div>

                                <p className="text-sm">Highest Rated Product</p>

                                <div className="-ms-0.5 flex">
                                  <svg
                                    className="h-5 w-5 text-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                  </svg>

                                  <svg
                                    className="h-5 w-5 text-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                  </svg>

                                  <svg
                                    className="h-5 w-5 text-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                  </svg>

                                  <svg
                                    className="h-5 w-5 text-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                  </svg>

                                  <svg
                                    className="h-5 w-5 text-gray-200"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                  </svg>
                                </div>
                              </div>

                              {/* <p className="text-4xl font-bold"> */}
                              {/* USD ${item.price} */}
                              {/* <CurrencyFormat
                                  value={item.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"£"}
                                /> */}
                              {/* </p> */}
                            </div>

                            <div className="mt-4">
                              <div className="prose max-w-none text-black">
                                <p className="sm:text-3xl">{item.desc}</p>
                              </div>

                              <button className="mt-2 text-sm font-medium underline">
                                Read More
                              </button>
                            </div>

                            <div className="mt-8">
                              <fieldset>
                                <legend className="mb-1 text-lg font-medium">
                                  Color
                                </legend>

                                <div className="flex flex-wrap gap-1">
                                  {item.productImageList.map(
                                    (coloritem, index) => (
                                      <label
                                        htmlFor={"color_" + coloritem.desc}
                                        className="cursor-pointer"
                                        key={index}
                                        onClick={() =>
                                          handleCurrentImage(coloritem)
                                        }
                                      >
                                        <input
                                          type="radio"
                                          name="color"
                                          id={"color_" + coloritem.desc}
                                          className="peer sr-only"
                                        />

                                        <span className="group inline-block rounded-full border px-3 py-1 text-lg font-medium peer-checked:bg-black peer-checked:text-white pill">
                                          {coloritem.desc}
                                        </span>
                                      </label>
                                    )
                                  )}
                                </div>
                              </fieldset>

                              <fieldset className="mt-4">
                                <legend className="mb-1 text-lg font-medium">
                                  Size
                                </legend>

                                <div className="flex flex-wrap gap-1">
                                  {item.productSizeList.map((size, index) => (
                                    <label
                                      htmlFor={"size_" + size}
                                      className="cursor-pointer"
                                      key={index}
                                      onClick={() => handleCurrentSize(size)}
                                    >
                                      <input
                                        type="radio"
                                        name="size"
                                        id={"size_" + size}
                                        className="peer sr-only"
                                      />

                                      <span className="group inline-flex h-8 w-8 items-center justify-center rounded-full border text-lg font-medium peer-checked:bg-black peer-checked:text-white pill">
                                        {size}
                                      </span>
                                    </label>
                                  ))}
                                </div>
                              </fieldset>

                              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                                <div className="custom-number-input h-10 w-full col-span-1 lg:col-span-2">
                                  <label
                                    htmlFor="custom-input-number"
                                    className="w-full text-gray-700 text-lg font-semibold"
                                  >
                                    Quantity
                                  </label>
                                  <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1 spinners-container">
                                    <input
                                      type="number"
                                      className="outline-none focus:outline-none text-center w-20 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                                      name="custom-input-number"
                                      id="quantity"
                                      min="1"
                                      value={quantity}
                                      onChange={(e) =>
                                        setQuantity(e.target.value)
                                      }
                                    />

                                    <div className="spinners">
                                      <button
                                        onClick={(e) =>
                                          setQuantity(quantity + 1)
                                        }
                                        className="spinner increment"
                                      >
                                        &#9650;
                                      </button>
                                      <button
                                        onClick={(e) =>
                                          setQuantity(quantity - 1)
                                        }
                                        className="spinner decrement"
                                      >
                                        &#9660;
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="custom-number-input mt-10 h-10 w-full col-span-1 lg:col-span-2">
                                  <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1 items-center justify-end gap-x-8">
                                    <button
                                      onClick={() => handleAddToCart(item)}
                                      className="block rounded bg-green-600 px-2 py-3 text-2xl font-medium text-white hover:bg-green-500 w-full"
                                    >
                                      Add to Cart
                                    </button>

                                    <button
                                      onClick={() => funcProp("world")}
                                      className="block rounded bg-green-600 px-2 py-3 text-2xl font-medium text-white hover:bg-green-500 w-full"
                                    >
                                      Checkout
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Checkout />
      )}
    </>
  );
};

export default ProductViewCheckout;
